import React from "react";
import Layout from "../components/layout-contents";
import styles from "../styles/review.module.css";
import Container from "../components/container";
import { graphql } from "gatsby";

const User = (props) => (
  <div className={styles.topUser}>
    <div className={styles.descUser}>
      <img
        src="/images/about/dark_demon/darkdemon_logo.png"
        className={styles.logoUser}
        alt=""
      />
      <p className={styles.excerpt}>
      Videogame addict and nerd, Dark Demon reviews the weirdest games from the indie universe. 
      More than 20 years of gameplay makes his crypt a gamers must.
      </p>
      <div className={styles.linkUser}>
        <h3 style={{ marginLeft: "0px", marginBottom: "0px" }}>
          <a href="mailto:darkdemon@codafanzine.net">CONTACT</a>
        </h3>
      </div>
    </div>
    <a href={props.file} target="_blank" rel="noreferrer" style={{ display: "contents" }}>
      <img
        src="/images/about/dark_demon/darkdemon_image.png"
        className={styles.imageUser}
        alt=""
      />
    </a>
  </div>
);

const Post = (props) => (
  <div className={styles.review}>
    <div className={styles.logodesc}>
      <img src={props.logo} className={styles.logo} alt="" />
      <div className={styles.description}>
        <p className={styles.excerpt}>{props.excerpt}</p>
      </div>
    </div>
    <div>
      <a href={props.link} target="_blank" rel="noreferrer" style={{ display: "contents" }}>
        <img src={props.image} className={styles.image} alt="" />
      </a>
    </div>
  </div>
);

/*
  <h1>About CSS Modules</h1>
    <p>CSS Modules are cool</p>
*/
export default ({ data }) => (
  <Layout>
    <Container>
      <User></User>
      {data.allDarkDemonsJson.edges.map(({ node }) => (
        <Post
          logo={node.logo}
          excerpt={node.excerpt}
          image={node.image}
          link={node.link}
        />
      ))}
    </Container>
  </Layout>
);

export const query = graphql`
  query {
    allDarkDemonsJson {
      edges {
        node {
          logo
          excerpt
          image
          link
        }
      }
    }
  }
`;
